import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import '../App.css';

const firebaseConfig = {
    apiKey: "AIzaSyAehULslW-9k-BGcsLW1iGZ7jbu5sMpwHA",
    authDomain: "chat-ea79b.firebaseapp.com",
    databaseURL: "https://chat-ea79b-default-rtdb.firebaseio.com",
    projectId: "chat-ea79b",
    storageBucket: "chat-ea79b.appspot.com",
    messagingSenderId: "1044275961495",
    appId: "1:1044275961495:web:a1477f22018beecfb293d2",
    measurementId: "G-QQ9T3CX0K4"
  };
  
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userName, setUserName] = useState('');
  const [userColor, setUserColor] = useState('');
  const messagesEndRef = useRef(null);
  	
  useEffect(() => {
    // Prompt the user to enter their name when they enter the web page
    const name = prompt('Please enter your name:');
    if (name) {
      setUserName(name);
      setUserColor(getRandomColor());
    }
  }, []);

  useEffect(() => {
    const db = firebase.database().ref('messages').limitToLast(10); // Limit to last 10 messages
    db.on('value', snapshot => {
      const data = snapshot.val();
      if (data) {
        const messagesArray = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setMessages(messagesArray);
        scrollToBottom();
      } else {
        setMessages([]);
      }
    });
    return () => {
      db.off(); // Unsubscribe from Firebase listener when component unmounts
    };
  }, []);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sendMessage = () => {
    if (newMessage.trim() !== '') {
      const db = firebase.database().ref('messages');
      db.push({
        text: newMessage,
        userName: userName,
        userColor: userColor,
        timestamp: firebase.database.ServerValue.TIMESTAMP
      });
      setNewMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <div className="w-full max-w-lg mx-auto mt-4">
      <div className="bg-gray-100 p-4 rounded-lg shadow-md">
        <div className="overflow-y-auto max-h-60">
          {messages.map(message => (
            <div key={message.id} className="mb-2">
              <p className="text-sm font-semibold">
              <span style={{ color: message.userColor }}>{message.userName}: </span>
                {message.text}
              </p>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="mt-4 flex">
          <input
            type="text"
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Type your message..."
            className="flex-grow px-3 py-2 rounded-l-md focus:outline-none focus:ring focus:border-blue-300"
          />
          <button
            onClick={sendMessage}
            className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;