import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Chat from './components/Chat'; // Import the Chat component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Chat />} /> {/* Always render Chat component */}
      </Routes>
    </Router>
  );
}

export default App;